import React, { useState } from 'react'

import {
  AlertCircleIcon,
  ErrorIcon,
  TabList,
  TabListItem
} from '@te-digi/styleguide'

const TabListItemStatusExample = () => {
  const [active, setActive] = useState(0)
  const [activeSecondary, setActiveSecondary] = useState(0)

  const shiftSelection = (direction: 'left' | 'right') => {
    if (direction === 'right') {
      setActive(active === 1 ? 0 : 1)
    } else if (direction === 'left') {
      setActive(active === 0 ? 1 : 0)
    }
  }

  const shiftSelectionSecondary = (direction: 'left' | 'right') => {
    if (direction === 'right') {
      setActiveSecondary(activeSecondary === 1 ? 0 : 1)
    } else if (direction === 'left') {
      setActiveSecondary(activeSecondary === 0 ? 1 : 0)
    }
  }

  return (
    <>
      <TabList
        ariaLabelledby="heading-id1"
        items={[
          <TabListItem
            active={active === 0}
            color="secondary"
            iconRight={<AlertCircleIcon arial-label="Huomioitavaa" />}
            id={'tab-' + 0}
            key={'tab-' + 0}
            onClick={() => setActive(0)}
            panelId={'panel-' + 0}
          >
            Harvest without plasma
          </TabListItem>,
          <TabListItem
            active={active === 1}
            color="danger"
            iconRight={<ErrorIcon arial-label="Virheitä" />}
            id={'tab-' + 1}
            key={'tab-' + 1}
            onClick={() => setActive(1)}
            panelId={'panel-' + 1}
          >
            Always convert a particle
          </TabListItem>,
          <TabListItem
            active={active === 2}
            id={'tab-' + 2}
            key={'tab-' + 2}
            onClick={() => setActive(2)}
            panelId={'panel-' + 2}
            disabled
          >
            Red alert
          </TabListItem>
        ]}
        onSelectNext={() => shiftSelection('right')}
        onSelectPrevious={() => shiftSelection('left')}
      />
      <TabList
        ariaLabelledby="heading-id2"
        items={[
          <TabListItem
            active={activeSecondary === 0}
            color="secondary"
            iconRight={<AlertCircleIcon />}
            id={'tab-' + 0}
            key={'tab-' + 0}
            onClick={() => setActiveSecondary(0)}
            panelId={'panel-' + 0}
          >
            Harvest without plasma
          </TabListItem>,
          <TabListItem
            active={activeSecondary === 1}
            color="danger"
            iconRight={<ErrorIcon />}
            id={'tab-' + 1}
            key={'tab-' + 1}
            onClick={() => setActiveSecondary(1)}
            panelId={'panel-' + 1}
          >
            Always convert a particle
          </TabListItem>,
          <TabListItem
            active={activeSecondary === 2}
            id={'tab-' + 2}
            key={'tab-' + 2}
            onClick={() => setActiveSecondary(2)}
            panelId={'panel-' + 2}
            disabled
          >
            Red alert
          </TabListItem>
        ]}
        onSelectNext={() => shiftSelectionSecondary('right')}
        onSelectPrevious={() => shiftSelectionSecondary('left')}
        variant={'secondary'}
      />
    </>
  )
}

export { TabListItemStatusExample }
