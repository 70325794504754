import React, { useState } from 'react'

import {
  Heading,
  Paragraph,
  TabList,
  TabListItem,
  TabPanel
} from '@te-digi/styleguide'

const TAB_DATA = [
  {
    heading: 'Tapahtumat',
    panel: 'Tapahtumavirran sisältö'
  },
  {
    heading: 'Tilanne',
    panel: 'Työnhaku voimassa'
  },
  {
    heading: 'Arvioinnit',
    panel: 'Palvelutarvearvio'
  },
  {
    heading: 'Työttömyysturva',
    panel: 'Alemman tason välilehtiä'
  },
  {
    heading: 'Työnvälitys',
    panel: 'Työtarjoukset'
  },
  {
    heading: 'Kokemus ja osaaminen',
    panel: 'Työkokemus ja koulutus'
  },
  {
    heading: 'Suunnitelma',
    panel: 'Ei voimassa olevia tai aiempia suunnitelmia'
  },
  {
    heading: 'Palvelut',
    panel: 'Tarkastele asiakkaan palveluita ja kirjaa palvelun tilannetietoa'
  },
  {
    heading: 'Henkilötiedot',
    panel: 'Perustiedot'
  }
]

const TabListExample = () => {
  const [active, setActive] = useState(3)

  const numberOfItems = TAB_DATA.length

  const shiftSelection = (direction: 'left' | 'right') => {
    if (direction === 'right') {
      setActive(active === numberOfItems - 1 ? 0 : active + 1)
    } else if (direction === 'left') {
      setActive(active === 0 ? numberOfItems - 1 : active - 1)
    }
  }

  return (
    <>
      <Heading
        level={1}
        id="heading-id1"
      >
        Otsikko (h1) joka kuvaa myös täbien merkityksen
      </Heading>
      <TabList
        ariaLabelledby="heading-id1"
        items={TAB_DATA.map((item, i) => (
          <TabListItem
            active={active === i}
            id={'tab-' + i}
            key={'tab-' + i}
            onClick={() => setActive(i)}
            panelId={'panel-' + i}
          >
            {item.heading}
          </TabListItem>
        ))}
        onSelectNext={() => shiftSelection('right')}
        onSelectPrevious={() => shiftSelection('left')}
      />

      {TAB_DATA.map(
        (item, i) =>
          active === i && (
            <TabPanel
              id={'panel-' + i}
              key={'panel-' + i}
              tabId={'tab-' + i}
            >
              <Heading level={2}>{item.heading}</Heading>
              <Paragraph>{item.panel}</Paragraph>
            </TabPanel>
          )
      )}
    </>
  )
}

export { TabListExample }
